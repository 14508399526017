.flex, .horizontal, .vertical, .vertical-fill {
  display: flex;
}
.horizontal {
  flex-direction: row;
}
.vertical, .vertical-fill {
  flex-direction: column;
}
.fill, .vertical-fill {
  height: 100%;
}
.wrap {
  flex-wrap: wrap;
}
.none {
  flex: none;
}
.one {
  flex: 1;
}
.two {
  flex: 2;
}

.three {
  flex: 3;
}

.four {
  flex: 4;
}

.five {
  flex: 5;
}

.six {
  flex: 6;
}

.seven {
  flex: 7
}

.eight {
  flex: 8
}

.nine {
  flex: 9
}

.ten {
  flex: 10;
}

.eleven {
  flex: 11;
}

.twelve {
  flex: 12
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}
