.csdm {
  &__card {
    margin: var(--margin-extra-tiny);
    box-shadow: ' 0 0 5px 2px rgb(221, 225, 230)';
    overflow-y: 'auto'; 
    padding: var(--padding-small);
    background-color: var(--white);

    &__filter {
      margin: 0 var(--margin-tiny) var(--margin-extra-tiny) var(--margin-extra-tiny);
      box-shadow: ' 0 0 5px 2px rgb(221, 225, 230)';
      overflow-y: 'auto'; 
      padding: var(--padding-tiny);
      background-color: var(--tertiary);
    }
    &__right {
      margin: var(--margin-extra-tiny) var(--margin-tiny) var(--margin-extra-tiny) var(--margin-extra-tiny);
      box-shadow: ' 0 0 5px 2px rgb(221, 225, 230)';
      overflow-y: 'auto'; 
      padding: var(--padding-small);
      background-color: var(--white);
      
    }
    &__bottom {
      margin: var(--margin-extra-tiny) var(--margin-extra-tiny) var(--margin-tiny) var(--margin-extra-tiny);
      box-shadow: ' 0 0 5px 2px rgb(221, 225, 230)';
      overflow-y: 'auto'; 
      padding: var(--padding-small);
      background-color: var(--white);

      &__right {
        margin: var(--margin-extra-tiny) var(--margin-tiny) var(--margin-tiny) var(--margin-extra-tiny);
        box-shadow: ' 0 0 5px 2px rgb(221, 225, 230)';
        overflow-y: 'auto'; 
        padding: var(--padding-small);
        background-color: var(--white);
      }
      
    }

  }
}