.dsp_icon_menu {
  font-size: 20px !important;
}

.dsp_submenu_text_span {
  font-size: 14px;
}

.logo {
  width: 80%;
  padding: 20px;
}

.ant-menu{
  color: black !important;
}

.logo-shield {
  width: 70%;
  display: block;
  margin: 0 auto;
}
.shell-sider-menu {
  background-color: transparent !important;
  box-shadow: rgb(221, 225, 230) 0px 0px 5px 2px;
  width: 240px !important;
  max-width: 240px !important;
  flex: 0 0 240px !important;
  margin: 8px 4px 8px 8px;
}
.shell-sider-menu-collapsed {
  background-color: transparent !important;
  box-shadow: rgb(221, 225, 230) 0px 0px 5px 2px;
  margin:0;
}

/**
 ** Give the ant-menu-items the same border as the abn-sider-menu.
 ** I like the fact that there is a border on the right of 1 pixel.
 */
#SHELL-sider-menu .ant-menu-item {
  /* border-right: 1px solid black !important; */
}

/* The single menu items have a border. Set this to zero to get a uniform border */
#SHELL-sider-menu .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: 0px !important;
}

/* When hovering over submenu and its not open */
#SHELL-sider-menu .ant-menu-submenu-active {
  background-color: #9dd0ed !important;
}
#SHELL-sider-menu .ant-menu-submenu-selected {
  background-color: #9dd0ed !important;
  color: white !important;
}

/* When submenu is open/collapsed */
#SHELL-sider-menu .ant-menu-submenu-open {
  color: black !important;
  background-color: white !important;
}
/* When hovering over submenu */
#SHELL-sider-menu .ant-menu-submenu-title:hover {
  color: white !important;
  background-color: #9dd0ed !important;
}
#SHELL-sider-menu .ant-menu-submenu-title {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 4px;
  padding-bottom: 4px;
}
/* Overwriting arrow style on hover */
#SHELL-sider-menu .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
#SHELL-sider-menu .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.65)), to(rgba(0, 0, 0, 0.65))) !important;
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)) !important;
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)) !important;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)) !important;
}
/* Overwriting arrow style when submenu is opened */
#SHELL-sider-menu .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
#SHELL-sider-menu .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.65)), to(rgba(0, 0, 0, 0.65))) !important;
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)) !important;
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)) !important;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)) !important;
}
/* menu items */
#SHELL-sider-menu .ant-menu-item {
  /* // background-color:  #3ea3d9!important; */
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  /* // border-right: 1px solid #e8e8e8 !important; */
}

#SHELL-sider-menu .ant-menu-item > a{
  color: black !important;
}

#SHELL-sider-menu .ant-menu-item-active {
  background-color: #9dd0ed !important;
}
#SHELL-sider-menu .ant-menu-item-selected {
  background-color: #3ea3d9!important;
}

#SHELL-sider-menu .ant-menu-item-active > a, #SHELL-sider-menu .ant-menu-item-selected > a {
  color: white !important;
}

#SHELL-sider-menu .ant-menu-item:hover > a {
  color: white !important;
}


/* White border on right sight when menu item is selected */
#SHELL-sider-menu .ant-menu-vertical .ant-menu-item:after,
#SHELL-sider-menu .ant-menu-vertical-left .ant-menu-item:after,
#SHELL-sider-menu .ant-menu-vertical-right .ant-menu-item:after,
#SHELL-sider-menu .ant-menu-inline .ant-menu-item:after {
  /* // border-right: 3px solid white !important; */
}

#SHELL-sider-menu .ant-layout-sider-children {
  background-color: white;
}

.ant-layout-sider-zero-width-trigger {
  top: 0px !important;
  background-color: #3ea3d9 !important;
  color: black !important;
}

