body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  height: 100vh;
  margin: 0;
  padding: 0;
  width: 100vw;
}

h1 {
  color: #0d1a26;
  font-weight: 500;
  font-size: 24px;
}

#root {
  height: 100%;
}

.button_as_link {
  background:none!important;
  color:inherit;
  border:none;
  padding:0!important;
  font: inherit;
  cursor: pointer;
}

.menu-trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}

.menu-trigger:hover {
  color: #009286;
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}

.NotFound-404 {
  font-size: 60px;
  color: #37A2DB;
}

.page-header {
  font-size: 18px;
  margin-bottom: 10px;
}

.bordered-box-yellow-bottom-border {
  border: 1px solid rgb(236,236,236);
}

.form-spinner, .form-spinner>.ant-spin-container {
  height: 100%;
}

.device-card-title {
  cursor: default !important;
}

.ant-table-thead > tr > th[colspan] {
  text-align: left;
}

.device-card {
  -webkit-touch-callout: auto !important; /* iOS Safari */
    -webkit-user-select: auto !important; /* Safari */
     -khtml-user-select: auto !important; /* Konqueror HTML */
       -moz-user-select: auto !important; /* Old versions of Firefox */
        -ms-user-select: auto !important; /* Internet Explorer/Edge */
            user-select: auto !important;
}

/* tooltip color and background   */
/* 
.ant-tooltip-inner {
  background-color: var(--secondary);
  color: rgba(0, 0, 0, 0.65);
}
.ant-tooltip-arrow::before{
  background-color: var(--secondary);
} */
