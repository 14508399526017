.csdm {
  &__table {
    display: flex;
    flex: 10;
    flex-direction: column;
    margin-top: var(--margin-small);
    margin-bottom: var(--margin-small);
    overflow-y: auto;

    &__header{
      min-height: 45px;
      align-items: center;
      display: flex;
      flex: 10;
      margin-bottom: var(--margin-tiny);
      background-color: var(--background-primary);

      &-white {
        min-height: 45px;
        align-items: center;
        display: flex;
        flex: 10;
        margin-bottom: var(--margin-tiny);
        background-color: var(--white);
      }

      &__data {
        min-height: 45px;
        padding: 0 var(--padding-tiny);
        align-items: center;
        display: flex;
        flex: 10;

        &-white {
          min-height: 45px;
          padding: 0 var(--padding-tiny);
          align-items: center;
          display: flex;
          flex: 10;
          background-color: var(--white);
        }

        &-none {
          min-height: 45px;
          align-items: center;
          padding: 0 var(--padding-tiny);
          display: flex;
          background-color: var(--background-primary);
          flex: none;
          min-width: 36px;

          &-white {
            min-height: 45px;
            align-items: center;
            padding: 0 var(--padding-tiny);
            display: flex;
            background-color: var(--white);
            flex: none;
            min-width: 36px;
          }
        }
      }

      &__sortable:hover {
        cursor: pointer;
        background-color: var(--background-dark);
      }
    }

    &__body {
      display: flex;
      flex: 10;
      flex-direction: column;
      align-items: flex-start;
      overflow-y: auto;

      &__row {
        display: flex;
        margin-bottom: var(--margin-tiny);
        align-items: center;
        width: 100%;
        display: flex;
        background-color: var(--background-primary);
        
        &-white {
          margin-bottom: var(--margin-tiny);
          align-items: center;
          width: 100%;
          display: flex;
          background-color: var(--white);
        }

        &-top {
          padding-bottom: var(--padding-tiny);
          align-items: center;
          display: flex;
          width:100%;
          background-color: var(--background-primary);
          &-white {
            padding-bottom: var(--padding-tiny);
            min-height: 48px;
            align-items: center;
            display: flex;
            width:100%;
            background-color: var(--white);
          }

        }

        &-bottom {
          padding-bottom: var(--padding-tiny);
          align-items: center;
          display: flex;
          flex-direction: row;
          width:100%;
          background-color: var(--background-primary);
          margin-bottom: var(--margin-tiny);
          &-white {
            padding-bottom: var(--padding-tiny);
            align-items: center;
            display: flex;
            flex-direction: row;
            margin-bottom: var(--margin-tiny);
            width:100%;
            background-color: var(--white);
          }
        }

        &__data {
          display: flex;
          flex: 10;
          padding: var(--padding-tiny);
          &-white {
            display: flex;
            flex: 10;
            padding: var(--padding-tiny);
            background-color: var(--white);
          }
          &-none {
            display: flex;
            flex: none;
            flex-direction: column;
            color: var(--secondary);
            font-size: var(--font-huge);
            align-items: center;
            padding: var(--padding-tiny);
            &-white {
              display: flex;
              flex: none;
              flex-direction: column;
              color: var(--secondary);
              font-size: var(--font-huge);
              align-items: center;
              min-width: 40px;
              padding: var(--padding-tiny);
              background-color: var(--white);
            }
          }

          &__type {
            &__date {
              color: var(--text-primary);
              font-size: var(--font-tiny);
            }
            &__event-type {
              color: var(--primary);
            }
          }
        }
      }
    }
  }
}