
:root {
  --margin-extra-tiny: 4px;
  --margin-tiny: 8px;
  --margin-small: 15px;
  --margin-medium: 20px;
  --margin-large: 30px;
  --margin-huge: 60px;
  --margin-gigantic: 100px;
}
