:root{
	--primary: #3ea3d9;
  --secondary: #9dd0ed;
  --tertiary: #d8ecf8;
  --success: #2AB473;
	--text-primary: #666666;
	--text-secondary: #FAFAFA;
	--text-light: #b1b3b3;  
  --border: #e5e5e5;
  --background-primary: #F2F2F2;
	--background-dark: #dcdcdc;
  --background-light: #F2F2F2;
  --white: #ffffff;
  --dark-grey: #1c1c1c;
  --error-color: #cb352d;
}
