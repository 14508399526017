.csdm {
  &__button {
    display: flex;
    width: 140px;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 3px;
    height: 30px;
    margin: 0 var(--margin-tiny);
    border: 1 solid var(--border);
    font-size: var(--font-large);
    cursor: pointer;
    background-color: var(--primary);
    color: var(--white);
    
    &__secondary {
      display: flex;
      width: 140px;
      justify-content: space-evenly;
      align-items: center;
      border-radius: 3px;
      height: 30px;
      margin: 0 var(--margin-tiny);
      border: 1 solid var(--border);
      cursor: pointer;
      font-size: var(--font-large);
      background-color: var(--white);
      color: var(--text-primary);
    }
    &__secondary:hover {
      color: var(--primary);
      border:1px solid var(--primary);
    }
    &__secondary:disabled {
      background-color: var(--background-primary);
      cursor: not-allowed;
      color: var(--text-primary);
      border: none;
    }

    &__round {
      display: flex;
      width: 28px;
      height: 28px;
      justify-content: space-evenly;
      align-items: center;
      border-radius: 50%;
      border: 1 solid var(--border);
      font-size: 14px;
      cursor: pointer;
      background-color: var(--primary);
      color: var(--white);
    }
    &__round:hover {
      background-color: #60BDE8;
    }
    &__round:disabled {
      background-color: var(--background-primary);
      cursor: not-allowed;
    }
  }
  &__button:hover {
    background-color: #60BDE8;
  }
  &__button:disabled {
    background-color: var(--background-primary);
    cursor: not-allowed;
  }

  &__button > i {
    font-size: 18px;
  }
}